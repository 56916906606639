<template lang="pug">
.my-trades-list
  template(v-if="items.length")
    .my-trades-list__toolbar.t-button-small.c-text.d-flex.justify-space-between.align-center.px-4
      span {{ totalItemsCount | tradesCount }}
      //- span Сортировка
    .my-trades-list__list.pa-4
      asset-card.mb-4(v-for="trade in items" :key="trade.id" :asset="trade")
        template(#extension)
          div(style="width: 100%")
            .d-flex.align-center.mb-4
              trade-kind.mr-2(:kind="trade.kind")
              .t-small.c-text(v-if="trade.end_date") До {{ trade.end_date | datetime }}
              .t-small.c-text(v-else) {{ trade.kind_text }}
            my-trade-status(no-fill :state="trade.user_status")
              div
                .t-small.c-text--dimmed Текущая цена
                .asset-price.asset-price--medium {{ trade.current_price }}
              div(v-if="trade.start_price")
                .t-small.c-text--dimmed.mb-2 Начальная цена
                .asset-price.c-text--dimmed {{ trade.start_price }}

      .mt-10.mb-8
        ui-btn.mb-4(
          v-if="totalItemsCount > items.length"
          min-height=40
          preset="rounded"
          width="100%"
          @click="fetchMoreTrades"
        )
          ui-icon(name="renew" left)
          | Показать ещё
        .t-caption-normal.text-center.c-black60 Показано {{ items.length }} из {{ totalItemsCount }}

  .my-trades-list__list--empty(v-else)
    titled-image(small title="Ничего не найдено")
      template(#image)
        img(src="~@/assets/auction.svg")
      | Не найдено аукционов по заданным критериям
</template>

<script>
  import AssetCard from '@/components/asset/AssetCard.vue';
  import TitledImage from '@/components/TitledImage.vue';
  import MyTradeStatus from '@/components/asset/components/MyTradeStatus.vue';
  import TradeKind from '@/components/asset/components/TradeKind.vue';
  import { service as tradesService } from '@/services/trades';

  import { UiLoader, UiBtn, UiIcon } from '@/uikit';

  import { createLogger } from '@/uikit/util/logger.js';
  const logger = createLogger('MyTradesList', 'orange');

  export default {
    components: {
      AssetCard,
      TitledImage,
      UiLoader,
      UiBtn,
      UiIcon,
      MyTradeStatus,
      TradeKind,
    },

    props: {
      category: { type: String, default: undefined },
    },

    data() {
      return {
        loading: false,
        items: [],
        totalItemsCount: undefined,
        currentPage: 1,
        perPage: 5,
      };
    },

    watch: {
      category(newValue, oldValue) {
        this.fetchTrades();
      },
    },

    mounted() {
      this.fetchTrades();
    },

    methods: {
      async fetchTrades() {
        try {
          this.currentPage = 1;
          this.$emit('update:loading', true);
          const { trades, total } = await tradesService.searchTrades(
            this.category,
            this.currentPage
          );
          this.items = trades;
          this.totalItemsCount = total;
        } catch (error) {
          logger.error(error);
        } finally {
          this.$emit('update:loading', false);
        }
      },

      async fetchMoreTrades() {
        try {
          this.currentPage++;
          this.$emit('update:loading', true);
          const { trades, total } = await tradesService.searchTrades(
            this.category,
            this.currentPage
          );
          this.items = [...this.items, ...trades];
          this.totalItemsCount = total;
        } catch (error) {
          logger.error(error);
        } finally {
          this.$emit('update:loading', false);
        }
      },
    },
  };
</script>

<style lang="scss">
  .my-trades-list {
    .my-trades-list__list {
      width: 100%;
      line-height: 18px;

      & .my-trade-status__badge {
        top: -4px;
      }
    }

    .my-trades-list__list--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 480px;
      padding: 32px;
    }
  }
</style>
