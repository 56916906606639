<template lang="pug">
app-layout
  .page-my-trades
    v-overlay.text-center(
      :value="loading"
      color="#fff"
      absolute
      opacity="0.9"
    )
      ui-loader(text="Загрузка...")
    h2.t-h1-mobile.my-6.mx-4 Мои аукционы

    v-tabs.page-my-trades__tabs.mb-4(v-model="tab")
      v-tab.ml-4(href="#running") Активные
      v-tab(href="#finished") Завершенные
      v-tab(href="#archived") Архив

    my-trades-list(:loading.sync="loading" :category="tab")
</template>

<script>
  import AppLayout from '@/layouts/AppLayout';
  import MyTradesList from '@/components/trades/MyTradesList.vue';

  import { UiLoader, UiBtn, UiIcon } from '@/uikit';

  import { createLogger } from '@/uikit/util';
  const logger = createLogger('PageFavorites', 'orange');

  export default {
    name: 'page-favorites',
    components: {
      AppLayout,
      UiLoader,
      UiBtn,
      UiIcon,
      MyTradesList,
    },

    data() {
      return {
        loading: false,
        tab: 'running',
      };
    },
  };
</script>

<style lang="scss">
  .page-my-trades {
    height: 100%;
    min-height: 480px;
  }

  .page-my-trades__tabs {
    color: c('primary', 'light');
  }
</style>
